import moment from "moment-timezone";

export const useBasicDateTimeFormatter = (timestamp: number) => {
  if (typeof timestamp !== "number") {
    return undefined;
  }

  const date = new Date(timestamp);

  // Handle invalid dates
  if (isNaN(date.getTime())) {
    return undefined;
  }

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  const absoluteTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const currentTime = moment.tz(absoluteTimezone);
  const timezone = currentTime.format("z");

  return `${month} ${day}, ${year} at ${date.toLocaleTimeString()} ${timezone}`;
};
